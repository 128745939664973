<template>
    <div class="common-page-header flex-center-bet">
        <div class="flex-center page-header-title">
            <span v-if="$attrs.image" class="iconfont-border">
                <img class="c-top-coupon-icon" :src="`/static/images/${$attrs.image}.svg`" />
            </span>
            <!--<span v-else class="iconfont-border">-->
            <!--    <i class="iconfont" :class="[$attrs.icon || 'icon-liwuhuodong']"></i>-->
            <!--</span>-->
            <span v-else class="margin-r10 flex-center">
                <svg aria-hidden="true" width="25" height="25">
                    <use :xlink:href="`#${icon || 'icon-yingxiaohuodong-xian-yuan'}`"></use>
                </svg>
            </span>
            <span v-if="$attrs.back !=='none'" class="go-back" @click="goBack()">
                <i class="iconfont icon-fanhui"></i>返回
            </span>{{title}}
            <span v-if="prompt" class="header-prompt">{{prompt}}</span>
            <div class="slot-content">
                <slot name="slot-content"></slot>
            </div>
        </div>
        <slot name="slot-right"></slot>
    </div>
</template>

<script>
    /**
     * 页面头部
     * @param icon {string} 图标
     * @param title {string} 标题
     * @param prompt {string} 提示信息
     * @param back {string} none不显示返回按钮
     * slot:
     * slot-content 标题后面更多内容内容显示
     * slot-right 头部最右侧自定义
     */
	import {getRouteList} from './data'
    export default {
        name: "index",
        props: {
            title: {
                type: String,
                default: ''
            },
            prompt: {
                type: String,
                default: ''
            },
            customBack: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                routeList: getRouteList(),
                icon: ''
            }
        },
        mounted() {
            this.getRouteIcon();
        },
        methods: {
	        getRouteIcon() {
                let name = this.$route.name;
                for(let item of this.routeList) {
                    if(item.routes.includes(name)) this.icon = item.icon
                }
            },
            goBack() {
                if(this.customBack) {
                    this.$emit('back');
                    return;
                }
                this.$router.go(-1);
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../../styles/common";
    /* 头部通用 */
    .common-page-header {
        padding: 0 20px;
        background-image: linear-gradient(-1deg, rgba(21, 28, 59, 0.29) 0%, rgba(28, 58, 174, 0.29) 100%);
        height: 55px;
        text-align: left;
        margin-bottom: 10px;
    }

    .page-header-title {
        white-space: nowrap;
        font-size: 14px;
        color: #ffffff;
        font-weight: bolder;
    }

    .iconfont-border {
        width: 25px;
        height: 25px;
        text-align: center;
        line-height: 25px;
        display: inline-block;
        background-image: url('http://cdn.zhimatech.com/bg-circle.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position-y: -1px;
        color: #017DFF;
        margin-right: 10px;
    }

    .iconfont-border .iconfont {
        font-size: 10px;
    }

    .slot-content {
        font-size: 12px;
        margin-left: 20px;
    }

    .go-back {
        margin-right: 12px;
        font-size: 12px;
        font-weight: 400;
        cursor: pointer;
        border-right: 1px solid #ffffff;
        padding-right: 10px;
        height: 20px;
        line-height: 20px;

        i {
            font-weight: bolder;
            margin-right: 10px;
            font-size: 10px;
        }
    }

    .header-prompt {
	    display: inline-block;
	    flex: 1;
        color: #AAB2C5;
        font-size: 12px;
        font-weight: normal;
        padding-left: 6px;
	    white-space: pre-wrap;
    }
</style>
