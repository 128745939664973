const parent = 'parkcoupon';

export default [
    {
        path: "/parkforms",
        name: "parkforms",
        meta: { title: "停车报表" },
        component: () => import('@/components/ParkManage/ParkForms'),
    },
    {
        path: "/parkcoupon",
        name: "parkcoupon",
        meta: { title: "停车券" },
        // component: () => import('@/components/ParkManage/parkCoupon/parkCoupon'),
        component: () => import('@/views/coupon/parkCoupon'),
    },
    // 新增停车券
    {
        path: "/parkaddcoupon",
        name: "parkaddcoupon",
        meta: { parent, name: 'parent' },
        component: () => import('@/components/ParkManage/parkCoupon/parkAddcoupon'),
    },
    {
        path: '/parkValuableCouponAdd',
        name: 'ParkValuableCouponAdd',
        meta: { parent, name: 'parent' },
        component: () => import('@/views/ValuableCoupon/edit'),
    },
    {
        path: '/parkValuableCouponDetail',
        name: 'ParkValuableCouponDetail',
        meta: { parent, name: 'parent' },
        component: () => import('@/views/ValuableCoupon/detail'),
    },
    {
        path: '/parkValuableCouponReport',
        name: 'ParkValuableCouponReport',
        meta: { parent, name: 'parent' },
        component: () => import("@/components/QuickShopping/Report"),
    },
    // 优惠券详情
    {
        path: "/parkcoupondetail",
        name: "parkcoupondetail",
        meta: { parent, name: 'parent' },
        component: () => import('@/components/ParkManage/parkCoupon/parkCoupondetail'),
    },
    // 优惠券数据报表voucherreport
    {
        path: "/parkcouponreport",
        name: "parkcouponreport",
        meta: { parent, name: 'parent' },
        component: () => import('@/components/ParkManage/parkCoupon/parkCouponReport'),
    },
    // 停车设置
    {
        path: "/parkset",
        name: "parkset",
        meta: { title: "停车设置" },
        component: () => import('@/components/ParkManage/ParkSet'),
    },
    {
        path: "/parkedit",
        name: "parkedit",
        meta: { parent: 'parkset', name: 'parent', title: "停车设置" },
        component: () => import('@/components/ParkManage/ParkEdit'),
    },
    {
        path: "/licensePlateHistory",
        name: "LicensePlateHistory",
        meta: { title: "车牌记录" },
        component: () => import('@/views/LicensePlateHistory'),
    },
];
